import React, { useMemo } from 'react';

import {
  PaymentMethods,
  BannerWarning,
  PrimaryButtonLarge,
  LocaleSelection,
  HomeCustomisation,
} from '@fingermarkglobal/cringer.components';

import Logo from '../../../assets/images/logo.svg';
import DefaultHeroImage from '../../../assets/images/bucket.png';
import WashHandsImage from '../../../assets/images/wash-hands.png';

const SecondaryHome = ({
  t = () => {},
  card,
  cash,
  icons,
  className = '',
  healthMsg = true,
  languages = [],
  isCurrentLang,
  primaryAction,
  secondaryAction,
  onChangeLanguage = () => {},
  accessibilityEnabled = false,
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
}) => {
  const titleClass = useMemo(() => {
    return isCurrentLang('uae')
      ? 'text-4xl xs:text-5xl sm:text-6xl md:text-8xl'
      : 'text-6xl xs:text-8xl sm:text-9xl md:text-12xl';
  }, [isCurrentLang]);

  const subTitleClass = useMemo(() => {
    return isCurrentLang('uae')
      ? 'text-3xl xs:text-4xl sm:text-5xl md:text-7xl'
      : 'text-4xl xs:text-5xl sm:text-7xl md:text-9xl';
  }, [isCurrentLang]);

  const isBordered = !!(icons?.length || card);

  return (
    <HomeCustomisation.Provider
      fallback={{
        hero: {
          image: DefaultHeroImage,
        },
      }}
    >
      <HomeCustomisation.Background />

      <div
        data-test="secondary-home"
        className={`z-10 flex flex-col justify-between items-center h-full w-full overflow-y-hidden  ${className}`}
      >
        <div
          data-test="home-upper"
          className="flex flex-col items-center justify-start w-full h-full"
        >
          <PaymentMethods
            t={t}
            card={card}
            cash={cash}
            Logo={Logo}
            icons={icons}
            enableLogo={!accessibilityEnabled}
            className={`px-4 md:px-12 py-2 md:py-4 text-7xl ${
              isBordered ? 'border-2 border-black' : ''
            }  rounded ${isBordered && !accessibilityEnabled ? 'mt-6 sm:mt-16 md:mt-32' : ''}`}
          />

          <div
            className={`flex flex-col items-center justify-center mb-10 md:mb-0 mt-16 leading-none ${
              accessibilityEnabled ? 'mb-60' : ''
            }`}
          >
            <p className={`${titleClass} text-black font-extrabold`} data-test="home-title">
              {t('homeTitle')}
            </p>

            <p className={`${subTitleClass} text-black font-extrabold`} data-test="home-subtitle">
              {t('homeSubtitle')}
            </p>
          </div>

          {!accessibilityEnabled && <HomeCustomisation.HeroImage />}
        </div>

        <div
          data-test="home-lower"
          className={`flex flex-col-reverse items-center justify-start w-full h-1/2 md:h-full ${
            accessibilityEnabled ? 'mt-20 md:mt-24' : ''
          }`}
        >
          {showTermsAndConditionsButton && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-terms"
              className={`w-4/5 mb-${healthMsg ? '2' : '20'}`}
              title={t('homeTermsAndConditionsBannerWarningTitle')}
              description={t('homeTermsAndConditionsBannerWarningDescription')}
              additionalDescription={t('homeTermsAndConditionsBannerWarningAdditionalDescription')}
              additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
            />
          )}

          {healthMsg && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-health"
              image={WashHandsImage}
              className={`w-4/5 mb-${showTermsAndConditionsButton ? '2' : '20'}`}
            />
          )}

          <div
            data-test="home-services"
            className={`flex items-stretch justify-center ${
              healthMsg ? 'mb-32' : 'mb-96'
            } space-x-4 sm:w-4/5 sm:mb-16 xs:space-x-8 sm:space-x-16`}
          >
            {!!secondaryAction && (
              <PrimaryButtonLarge
                data-test="button-secondary"
                className="w-1/2 home-primary-button"
                onClick={secondaryAction}
              >
                {t('homeSecondaryAction')}
              </PrimaryButtonLarge>
            )}

            {!!primaryAction && (
              <PrimaryButtonLarge onClick={primaryAction} className="w-1/2 home-primary-button">
                {t('homePrimaryAction')}
              </PrimaryButtonLarge>
            )}
          </div>
        </div>

        <div className="flex justify-center w-full mt-8 md:mb-0" data-test="home-footer">
          <LocaleSelection languages={languages} onChangeLanguage={onChangeLanguage} />
        </div>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { SecondaryHome };
